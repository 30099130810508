import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  Props as ServiceProps,
  Service,
} from 'app/components/RoomIntro/Service'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  bookingCTA?: ButtonProps
  languageCode: string
  mainImage?: ImageProps
  mainService?: ServiceProps
  pax?: string
  size?: string
  thumbnails?: ImageProps[]
  title: string
  URL?: string
}

export const Room = memo(function Room({
  bookingCTA,
  languageCode,
  mainImage,
  mainService,
  pax,
  size,
  thumbnails,
  title,
  URL,
}: Props) {
  const section = title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

  return (
    <Container id={`room-${section}`} dial={5} row stretch wrap>
      <LeftSide dial={4}>
        {thumbnails ? (
          <Thumbs row space="between">
            {thumbnails.map((item, index) => (
              <Thumb key={index}>
                <Image {...item} />
              </Thumb>
            ))}
          </Thumbs>
        ) : null}
        {pax || size ? (
          <Info row>
            {pax ? <Pax>{pax}</Pax> : null}
            {size ? <Size>{size}</Size> : null}
          </Info>
        ) : null}
        {title ? <Title>{title}</Title> : null}
        {mainService ? (
          <MainService>
            <Service {...mainService} />
          </MainService>
        ) : null}
        {bookingCTA || URL ? (
          <Buttons row wrap>
            {bookingCTA ? <Button {...bookingCTA} /> : null}
            {URL ? (
              <Button
                label={useVocabularyData('details', languageCode)}
                URL={URL}
                variant="outline"
              />
            ) : null}
          </Buttons>
        ) : null}
      </LeftSide>
      <RightSide>{mainImage ? <Image {...mainImage} /> : null}</RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 9rem;
  &:first-of-type {
    margin-top: 7.5rem;
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
      }
    }
  }

  @media (max-width: 1023px) {
    margin-top: 4.5rem;
    &:first-of-type {
      margin-top: 3.75rem;
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding-right: 8.472vw;
  padding-left: 12.639vw;

  @media (max-width: 1199px) {
    padding-right: 5vw;
    padding-left: 8vw;
  }

  @media (max-width: 1023px) {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    order: 2;
    margin-bottom: 2rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
`

const Thumbs = styled(FlexBox)`
  width: 100%;
  margin-bottom: 3.375rem;

  @media (max-width: 1023px) {
    margin-bottom: 2.5rem;
  }
`

const Thumb = styled.div`
  width: calc(50% - 0.75rem);
  padding-bottom: calc(50% - 0.75rem);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: 0.1625rem;
  line-height: 1.125rem;
  margin-bottom: 0.8125rem;
  text-transform: uppercase;

  > span {
    &:nth-of-type(2) {
      display: flex;
      align-items: center;
      &:before {
        content: '';
        display: block;
        width: 0.375rem;
        height: 0.375rem;
        background: ${({ theme }) => theme.colors.variants.primaryDark2};
        border-radius: 50%;
        margin: 0 0.75rem;
      }
    }
  }

  @media (max-width: 1023px) {
    font-size: 0.75rem;
    letter-spacing: 0.0813rem;
  }
`

const Pax = styled.span``

const Size = styled.span``

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.primaryDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.375rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const MainService = styled.div`
  > div {
    width: 100%;
    margin-top: 3.375rem;
    img {
      width: auto;
      height: 1.875rem;
      max-height: none;
    }
    div {
      width: calc(100% - 2.625rem);
    }
  }

  @media (max-width: 1023px) {
    > div {
      margin-top: 2.5rem;
    }
  }
`

const Buttons = styled(FlexBox)`
  margin-top: 3.75rem;

  a {
    &:nth-of-type(2) {
      margin-left: 0.75rem;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 2.75rem;
  }
  @media (max-width: 424px) {
    flex-direction: column;
    width: 100%;

    a {
      width: 100%;
      &:nth-of-type(2) {
        margin-left: 0;
        margin-top: 0.75rem;
      }
    }
  }
`

const RightSide = styled.div`
  width: 50%;
  height: 88.9vh;
  max-height: 50rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    max-height: 40.625rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: 55vw;
    max-height: none;
  }
`
